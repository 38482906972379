.course-page{
    display: flex;
	align-items: center;
	justify-content: center;
    flex-direction: column;
	min-height: 100vh;
	margin: 0 150px;
}

.courses-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
	margin: 0 30px;
    
    
}

.course-heading h1{
	text-align: center;
	margin-left: 30px;
	margin-top: 20px;
	font-size: 35px;
}

.course {
	background-color: #fff;
	border-radius: 10px;
	box-shadow: -2px 1px 14px rgb(0 0 0 / 20%);
	display: flex;
	max-width: 100%;
	 margin: 20px;
	overflow: hidden;
	flex-direction: column;
	
}

.course h6 {
	opacity: 0.6;
	margin: 0;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.course h2 {
	letter-spacing: 1px;
	margin: 10px 0;
    color: white;
    font-size: 22px;
}


.course-preview {
	background: linear-gradient(to top right, #1325e8 -5%, #8f10b7 100%);
	color: #fff;
	padding: 30px;
	max-width: 300px;
    min-width: 250px;
}

.course-preview a {
	color: #fff;
	display: inline-block;
	font-size: 12px;
	opacity: 0.6;
	margin-top: 30px;
	text-decoration: none;
}

.course-info {
	padding: 30px;
	position: relative;
	width: 100%;
}
.course-info h2{
    color:black;
}
.course-info h5{
	font-size: 15px;
	height: 30px;
    width: 200px;
}
.course-info h6{
	font-size: 15px;
}


.progress-container {
	position: absolute;
	top: 30px;
	right: 30px;
	text-align: right;
	width: 150px;
}

.progress {
	background-color: #ddd;
	border-radius: 3px;
	height: 5px;
	width: 100%;
}

.progress::after {
	border-radius: 3px;
	background-color: #2A265F;
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	height: 5px;
	width: 66%;
}

.progress-text {
	font-size: 10px;
	opacity: 0.6;
	letter-spacing: 1px;
}

.btn {
	background-color: #2A265F;
	border: 0;
	border-radius: 50px;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
	color: #fff;
	font-size: 16px;
	padding: 12px 25px;
	position: absolute;
	bottom: 30px;
	right: 30px;
	letter-spacing: 1px;
    font-size: 10px;

}

@media (max-width: 870px) {
    .course-page{
		padding: 0;
		margin: 0;
	}
}