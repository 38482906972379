*{
  margin: 0;
  padding: 0;
  font-family: 'poppins', sans-serif;
  box-sizing: border-box;
}

.testimonials section{
    position: relative;
    width: 100%;
    background: #FFFFFF;
    overflow: hidden;
}

section .heading h1{
  display: inline-block;
  position: relative;
  color: #4C4E61;
  font-size: 30px;
  padding: 20px;
  width: 100%;
  text-align: center;
  font-weight: 700px;
}
.wrapper{
  width:100%;
  padding: 0 100px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.wrapper .container{
  position: relative;
  width: 400px;
  color: #fff;
  background: #e1e1e1;
  
  padding: 30px 20px;
  border-radius: 5px;
  transition: 0.3s ease;
  margin-top: 50px;
  margin-bottom: 50px;

}
.wrapper .container .profile{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -10%;
  width: 100%;
  display: block;
}
.wrapper .container .profile .imgBox{
position: relative;
height: 100px;
width: 100px;
margin: auto;
border: 8px solid #070c0d;
border-radius: 50%;
overflow: hidden;
}

.wrapper .container .profile .imgBox img{
  position: absolute;
  height: 100%;
  width: 100%;
  
  border-radius: 50%;
  object-fit: cover;
  transition: 0.3s ease;  
}
.wrapper .container h2{
  color: #8F00FF;
}
.wrapper .container p{
  
  color:#000;
  padding: 0 8px;
  opacity: 0.8;
}



.wrapper .container .left{
  font-size: 30px;
  display: block;
  text-align: left;
  color: #8F00FF;
}

.wrapper .container .right{
  font-size: 30px;
  display: block;
  text-align: right;
  color: #8F00FF;
}

.readMoreClassName{
  color: #8F00FF;
}
.readLessClassName{
  color: #8F00FF;
}

@media (max-width: 768px) {
.wrapper{
  padding: 0;
}


}

@media (max-width:420px){
  .wrapper .container{
    width: 330px;
  }
}

