.navbar {
	background-color: #202020f2;
	height: 80px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	font-size: 15px;
}

.navbar-logo {
	color: #fff;
	justify-self: start;
	margin-left: -1rem;
	cursor: pointer;
	text-decoration: none;
	font-size: 2rem;
	bottom: 55px;
}

.fa-firstdraft {
	margin-left: 0.5rem;
	font-size: 1.6rem;
}

.nav-menu {
	display: grid;
	grid-template-columns: repeat(9, auto);
	grid-gap: 5px;
	list-style: none;
	text-align: center;
	width: 75%;
	justify-content: start;
	justify-content: center;
}

.nav-item {
	display: flex;
	align-items: center;
}

.nav-links {
	color: white;
	text-decoration: none;
	padding: 0.1rem 1rem;
	text-transform: uppercase;
	font-size: 13px;
	letter-spacing: 1px;
	font-weight: 600;
}

.nav-links:hover {
	color: #f74a15;
	border-radius: 4px;
	transition: all 0.2s ease-out;
}

.fa-bars {
	color: #fff;
}

.nav-links-mobile {
	display: none;
}

.menu-icon {
	display: none;
}
.linkedin {
	color: #fff;
}
.facebook {
	color: #fff;
}
.twitter {
	color: #fff;
}
.instagram {
	color: #fff;
}
.header-social-icon span {
	color: #fff;
	display: block;
	font-size: 20px;
	font-weight: 700;
	font-family: "Poppins", sans-serif;
	margin-bottom: 20px;
}
.header-social-icon a {
	color: #fff;
	font-size: 16px;
	margin-right: 15px;
}
.header-social-icon i {
	height: 40px;
	width: 40px;
	text-align: center;
	line-height: 38px;
	border-radius: 50%;
}

@media screen and (max-width: 960px) {
	.NavbarItems {
		position: relative;
	}

	.nav-menu {
		display: flex;
		flex-direction: column;
		width: 100%;

		position: absolute;
		top: 80px;
		left: -100%;
		opacity: 1;
		transition: all 0.5s ease;
		justify-content: flex-start;
	}

	.nav-menu.active {
		background: #242222;
		left: 0;
		opacity: 90%;
		transition: all 0.5s ease;
		z-index: 1;
	}

	.nav-links {
		text-align: center;
		padding: 2rem;
		width: 100%;
		display: table;
	}

	.nav-links:hover {
		color: #f74a15;
		border-radius: 0;
	}

	.navbar-logo {
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(25%, 50%);
	}

	.menu-icon {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 60%);
		font-size: 1.8rem;
		cursor: pointer;
	}

	.fa-times {
		color: #fff;
		font-size: 2rem;
	}

	.nav-links-mobile {
		display: block;
		text-align: center;
		padding: 1.5rem;
		margin: 2rem auto;
		border-radius: 4px;
		width: 80%;
		background: #1888ff;
		text-decoration: none;
		color: #fff;
		font-size: 1.5rem;
	}

	.nav-links-mobile:hover {
		background: #fff;
		color: #1888ff;
		transition: 250ms;
	}

	.header-social-icon {
		display: none;
	}
}
