


  .software-page{
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
  }
  
  .software {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    
    font-style: normal;
    background-color: #F8F9FC;
    flex-wrap: wrap;
    margin-top: 100px;
    width: 85%;
  }
  



.card {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 260px;
    border: 1px solid #F2F1F5;
    border-radius: 8px;
    padding: 32px 48px;
    background-color: #FFFFFF;
    box-shadow: 0px 8px 16px -4px rgba(41, 48, 86, 0.2);
    transform: scale(1) translateY(16px);
    transition: all 0.4s cubic-bezier(0.56, 0.01, 0, 0.99);
    margin: 15px

  }
  .card:hover {
    transform: scale(1.03);
    box-shadow: 0px 10px 16px -9px rgba(41, 48, 86, 0.6);
  }

  .card:hover h1 {
    color: #F8F9FC;
  }
  .card:hover button {
    background: linear-gradient(to top right, #1325e8 -5%, #8f10b7 100%);
    color: #F8F9FC;
  }
  .card:hover .accent-line {
    transform: translateY(0%);
    z-index: -1;
  }
  .card .chip {
    display: flex;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    padding: 8px 12px;
    font-weight: 450;
    color: #2A265F;
    gap: 8px;
    background-color: #E8EFFF;
    border-radius: 4px;
    z-index: 1;
    transition: all 0.4s cubic-bezier(0.56, 0.01, 0, 0.99);
  }
  .card .chip svg > path {
    fill: #2A265F;
    transition: all 0.4s cubic-bezier(0.56, 0.01, 0, 0.99) 0.075s;
  }
  .card h1 {
    font-weight: 400;
    font-size: 25px;
    color: #293056;
    z-index: 1;
    transition: all 0.4s cubic-bezier(0.56, 0.01, 0, 0.99) 0.025s;
  }
  .card button {
    padding: 16px 40px;
    color: #F2F1F5;
    background: linear-gradient(to top right, #1325e8 -5%, #8f10b7 100%);
    border: 2px solid #F2F1F5;
    border-radius: 50px;
    font-weight: 700;
    font-size: 9px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    z-index: 2;
    transition: all 0.2s cubic-bezier(0.56, 0.01, 0, 0.99);
    cursor: pointer;
  }
  .card button:hover {
    background: linear-gradient(to top left, #1325e8 -5%, #8f10b7 90%);
    border: 2px solid #2A265F;
  }
 
  .card .accent-line {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 101%;
    background: linear-gradient(to top right, #1325e8 -5%, #8f10b7 100%);
    transform: translateY(97%);
    z-index: 0;
    transition: all 0.3s cubic-bezier(0.56, 0.01, 0, 0.99);
  }
  
  .link-text {
    
    color: #2A265F;
    font-size: 1.125em;
    font-weight: 600;
    line-height: 1.2;
    margin: auto 0 0;
    transition: color 0.45s ease;
  }
  
  
  
  