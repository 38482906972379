@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

/* .team{
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    background: #f2f2f2;
    position: relative;
  } */

  .team-container{
    max-width: 950px;
    width: 100%;
    overflow: hidden;
    padding: 40px 0;
  }
  .team-container .main-card{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    transition: 1s;
  }
  #two:checked ~ .main-card{
    margin-left: -100%;
  }
  .container .main-card .cards{
    width: calc(100% / 2 - 10px);
    display: flex;
    flex-wrap: wrap;
    margin: 0 20px;
    justify-content: space-between;
  }
  .main-card .cards .card{
    width: 255px;
    height: 355px;
    background: hsl(0deg 0% 98%);
    border-radius: 12px;
    padding: 30px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.4s ease;
  }
  .main-card .cards .card:hover{
    transform: translateY(-15px);
  }
  .cards .card .content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .cards .card .content .img{
    height: 150px;
    width: 150px;
    border-radius: 50%;
    padding: 3px;
    background: #FF676D;
    margin-bottom: 14px;
  }
  .card .content .img img{
    height: 100%;
    width: 100%;
    border: 3px solid #ffff;
    border-radius: 50%;
    object-fit: cover;
  }
  .card .content .name{
    font-size: 20px;
    font-weight: 500;
  }
  .card .content .job{
    font-size: 16px;
    color: #FF676D;
  }
  .card .content .media-icons{
    margin-top: 10px;
    display: flex;
  
  }
  .media-icons a{
    text-align: center;
    line-height: 33px;
    height: 35px;
    width: 35px;
    margin: 0 4px;
    font-size: 14px;
    color: #FFF;
    border-radius: 50%;
    border: 2px solid transparent;
    background: #FF676D;
    transition: all 0.3s ease;
  }
  .media-icons a:hover{
    color: #FF676D;
    background-color: #fff;
    border-color: #FF676D;
  }
   .container .button{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px;
  }
  .button label{
    height: 15px;
    width: 15px;
    border-radius: 20px;
    background: #fff;
    margin: 0 4px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .button label.active{
    width: 35px;
  }
  #one:checked ~ .button .one{
    width: 35px;
  }
  #one:checked ~ .button .two{
    width: 15px;
  }
  #two:checked ~ .button .one{
    width: 15px;
  }
  #two:checked ~ .button .two{
    width: 35px;
  }
  input[type="radio"]{
    display: none;
  }

  .team-title{
    width: 75%;
  }
  .team{
    display: flex;
    justify-content: center;
    padding: 70px 0px;
  }

 

  @media (max-width: 768px) {
    .main-card .cards .card{
      margin: 20px 0 10px 0;
      width: 230px;
    }
    .team-title {
        width: 100%;
    }
  }
  @media (max-width: 600px) {
    .main-card .cards .card{
      /* margin: 20px 0 10px 0; */
      width: 275px;
    }
    .team-title {
        width: 100%;
    }
  }
  @media (max-width: 950px) {
    .main-card .cards .card{
      margin: 20px 0 10px 0;
      width: 275px;
    }
    .team-title {
        width: 100%;
    }
}

.team-title h2{
    font-size: 30px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    text-align: center;
  
}

.team-title h2 span{
    color: #F24259;
}