/* Please ❤ this if you like it! */

@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=devanagari,latin-ext");

.my-popup-class {
	border-radius: 30px;
	padding: 20px;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #fff;
	min-height: 100vh;

	justify-content: center;

	color: rgb(48, 46, 46);
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.nav-container {
	background-color: #fbfbfb;
}

.about-container {
	width: 70vw;
	height: 40vh;
}

.about-content {
	font-size: 20px;
}
