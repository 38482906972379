:root {
    --red: hsl(0, 78%, 62%);
    --cyan: hsl(180, 62%, 55%);
    --orange: hsl(34, 97%, 64%);
    --blue: hsl(212, 86%, 64%);
    --varyDarkBlue: hsl(234, 12%, 34%);
    --grayishBlue: hsl(229, 6%, 66%);
    --veryLightGray: hsl(0, 0%, 98%);
    --weight1: 200;
    --weight2: 400;
    --weight3: 600;
  }
  
  .about-page{
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    background-color: var(--veryLightGray);
  }
  
  .attribution {
    font-size: 11px;
    text-align: center;
  }
  .attribution a {
    color: hsl(228, 45%, 44%);
  }
  
  h1:first-of-type {
    font-weight: var(--weight1);
    color: var(--varyDarkBlue);
  }
  
  h1:last-of-type {
    color: var(--varyDarkBlue);
  }
  
  @media (max-width: 400px) {
    h1 {
      font-size: 1.5rem;
    }
  }
  
  .header {
    text-align: center;
    line-height: 0.8;
    margin-bottom: 50px;
    margin-top: 30px;
  }
  
  .header p {
    margin: 0 auto;
    line-height: 2;
    color: var(--grayishBlue);
  }
  
  .box p {
    color: var(--grayishBlue);
  }
  
  .box {
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px var(--grayishBlue);
    padding: 30px;
    margin: 20px;
  }
  
  img {
    float: right;
  }
  
  @media (max-width: 450px) {
    .box {
      height: 200px;
    }
  }
  
  @media (max-width: 950px) and (min-width: 450px) {
    .box {
      text-align: center;
      height: 180px;

    }

    .about_info{
      display: none;
    }

    
  }
  
  .cyan {
    border-top: 3px solid var(--cyan);
  }
  .red {
    border-top: 3px solid var(--red);
  }
  .blue {
    border-top: 3px solid var(--blue);
  }
  .orange {
    border-top: 3px solid var(--orange);
  }
  
  h2 {
    color: var(--varyDarkBlue);
    font-weight: var(--weight3);
  }
  
  @media (min-width: 950px) {
    .row1-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .row2-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .box-down {
      position: relative;
      top: 150px;
    }
    .box {
      width: 20%;
    }
    .header p {
      width: 30%;
    }
    
    
}

.about {
  padding: 20px 0 20px 0;
  background-color: #d3d3d34d;

  }
  
  .about .heading h2 {
    font-size: 30px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    text-align: center;
  
  }
  
  .about .heading h2 span {
    color: #F24259;
  }
  
  .about .heading p {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.7;
    color: #999999;
    margin: 20px 0 30px;
    padding: 0;
  }

  .header .heading h2 {
    font-size: 28px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    text-align: center;
  
  }
  
  .header .heading h2 span {
    color: #f05006;
  }

  
  .about h3 {
    font-size: 25px;
    font-weight: 700;
    margin: 0;
    padding: 20px 0 0 0;

  }
  
  .about p {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.7;
    color: #999999;
    margin: 20px 0 15px;
    padding: 0;
  }
  
  .about h4 {
    font-size: 15px;
    font-weight: 500;
    margin: 8px 0;
  }
  
  .about h4 i {
    color: #F24259;
    margin-right: 10px;
  }

  .img-fluid {
    max-width: 100%;
    height: auto;
    width: 500px;
    border-radius: 10px;
}

  
  
  