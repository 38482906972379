.subject{
  padding-bottom: 30px;
    display: flex;
    justify-content: center;
}
.ui.accordion .title:not(.ui){
  border: 4px solid #f3e8ff;
  border-radius: 12px;
  background-color: #f3e8ff;
  color: #581c87;
  font-size: 20px;
  font-weight:600;
  margin: 10px;
}
.question-page{
    padding: 50px; 
}
.topics{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.ui.accordion {
    background-color: #FFFFFF;
    font-size: 25px;
    width: 50%;
    border-radius: 12px;
  }
  .ui.accordion .active.content{
    margin: 10px 0 0 60px;
  }
  .ui.accordion li{
    font-size: 20px;
  }

  
  @media only screen and (min-width: 768px) {
    .ui.accordion {
      width: 75%;
    }
  }
  @media only screen and (max-width: 480px) {
    .ui.accordion {
      width: 100%;
    }
    .ui.accordion li{
      font-size: 15px;
    }
    .ui.accordion .active.content{
      margin: 10px 0 0 40px;
    }
    .question-page {
      padding: 5px;
  }
  .subject{
    width: 100%;
    padding :5px;
  }
  }
  @media only screen and ((min-width : 480px) and (max-width: 768px )) {
    .ui.accordion {
      width: 90%;
    }
    .ui.button .button{
      flex: inherit;
      padding: 1em 2em;
    }
    
    .question-page {
      padding: 5px;
  }
  }


